$product-detail-image-zoom-top: auto;
$product-detail-image-zoom-bottom: 0;
$product-detail-image-zoom-right: 0;
$product-detail-image-badge-max-width: 40%;
$product-detail-image-badge-large-top: 0;
$product-detail-image-zoom-display: none;
$product-detail-image-zoom-display--large: block;
$product-detail-image-view360-top: rem-calc(70);
$product-detail-image-view360-right: rem-calc(26);

// Horizontal carousel
$product-detail-image-alternatives-horizontal-max-width--large: rem-calc(510);
$product-detail-image-alternatives-horizontal-padding: rem-calc(0 20);
$product-detail-image-alternatives-horizontal-margin: rem-calc(16) auto;
$product-detail-image-alternatives-horizontal-margin--large: rem-calc(20) auto;

@import "@lora/05-components/product/product-detail-image";
