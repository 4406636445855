//Checkout Sections
@import '@root/05-components/form/select';

$form-select-padding: rem-calc(0 25 0 20);
$form-select-padding-mobile: rem-calc(15 0 0 0);

.c-select {
    @include breakpoint(medium down) {
        padding: $form-select-padding-mobile;
    }
}