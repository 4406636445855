$product-variations-carousel-link-color: color(dark);
$product-variations-carousel-link-selected-color: color(dark);
$product-variations-carousel-link-border: 1px solid color(border);
$product-variations-carousel-link-background: transparent;
$product-variations-carousel-link-hover-border: 1px solid color(secondary-border);
$product-variations-carousel-link-disabled-border: $product-variations-carousel-link-border;
$product-variations-carousel-link-font: 400 #{rem-calc(14)} / rem-calc(20) $font-primary;
$product-variations-carousel-link-selected-border: 2px solid color(primary);
$product-variations-carousel-link-selected-font: 500 #{rem-calc(14)} / rem-calc(20) $font-tertiary;
$product-variations-carousel-link-selected-background: color(secondary);
$product-variations-carousel-link-border-radius: rem-calc(0);
$product-variations-carousel-link-disabled-color: color(secondary-border);
$product-variations-carousel-disabled-price-value-color: $product-variations-carousel-link-disabled-color;
$product-variations-carousel-selected-price-value-color: color(element);
$product-variations-carousel-link-disabled-selected-border: 2px solid color(primary);
$product-variations-carousel-price-color: color(element);

@import "@lora/05-components/product/product-variations-carousel";