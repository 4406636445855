$search-refinement-title-expanded-color: color(dark);
$search-refinement-title-expanded-font: 500 #{rem-calc(12)} / 1.15 $font-primary;
$search-refinement-title-font: 100 #{rem-calc(12)} / 1.15 $font-primary;
$search-refinement-title-expanded-padding--large: rem-calc(14 20 35);
$search-refinement-title-padding--large: rem-calc(15 0 15 40);
$search-refinement-applied-qty-background: color(primary-active);
$search-refinement-applied-qty-color: color(dark);
$search-refinement-applied-qty-margin: rem-calc(0 0 0 130);
$search-refinement-border-bottom: 1px solid color(border);
$search-refinement-title-expanded-border-bottom: $search-refinement-border-bottom;
$search-refinement-content-padding--large: rem-calc(1 0 14 20);
$search-refinement-title-font--large: 300 #{rem-calc(12)} / 1.15 $font-primary;
$search-refinement-title-text-transform--large: uppercase;

@import "@lora/05-components/search/search-refinement";