
                            $is-app_autoreplenishment-enabled: true;
                            $replenishment-selector-item-background: transparent;
$replenishment-selector-label-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-tertiary;
$replenishment-selector-price-font: 400 #{rem-calc(18)} / #{rem-calc(20)} $font-secondary;
$replenishment-selector-price-old-font: 400 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$replenishment-selector-price-old-margin: rem-calc(0 8 0 0);

//Item
$replenishment-selector-item-padding: rem-calc(8 16);
$replenishment-selector-item-padding--large: $replenishment-selector-item-padding;

@import "@root/05-components/replenishment-selector";
                        