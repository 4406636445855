$product-swatches-toggle-text-decoration: underline;
$product-swatches-toggle-border-bottom: 0 none;
$product-swatches-toggle-text-transform: none;
$product-swatches-toggle-font--large: #{rem-calc(12)} / 1.25 $font-primary;
$product-swatches-grouped-tab-font: #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$product-swatches-grouped-tab-border-radius: none;
$product-swatches-grouped-tab-padding: rem-calc(4 8);
$product-swatches-grouped-tab-padding--large: $product-swatches-grouped-tab-padding;
$product-swatches-grouped-tab-margin: rem-calc(0 8);

@import "@lora/05-components/product/product-swatches";