$form-select-font-sizes: (
    "small": rem-calc(12),
    "normal": rem-calc(12),
    "large": rem-calc(14)
);

$form-select-size: (
    "small": rem-calc(25),
    "normal": $global-input-primary-height,
    "large": rem-calc(50)
);

$form-select-secondary-arrow-color: color(dark);
$form-select-arrow-size: rem-calc(6);
$form-select-font-weight: 300;
$form-select-font-weight-hover: 100;
$form-select-border-radius: rem-calc(30);
$form-select-border-color: color(border);
$form-select-third-background: color(light);
$form-select-third-color: color(text);
$form-select-third-radius: 0;
$form-select-third-arrow-color: color(dark);
$form-select-height: rem-calc(50);
$form-select-third-option-color: color(dark);
$form-select-color-hover: color(dark);
$form-select-filter-button-icon-size: rem-calc(13);
$form-select-filter-background: color(light);
$form-select-text-transform: uppercase;
$form-select-label-float-margin: rem-calc(0 0 0 10);

// Single value
$form-select-single-value-label-float-left: rem-calc(-10);

@import "@lora/05-components/form/select";