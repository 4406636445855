$video-library-item-name-font-family: $font-primary;
$video-library-item-name-font-family--large: $font-secondary;
$video-library-item-name-font-size: rem-calc(14);
$video-library-item-name-font-size--large: rem-calc(18);
$video-library-item-name-font-weight--large: normal;
$video-library-item-border: none;
$video-library-item-icon-width: rem-calc(32);
$video-library-item-icon-width--large: $video-library-item-icon-width;
$video-library-item-icon-height: rem-calc(32);
$video-library-item-icon-height--large: $video-library-item-icon-height;
$video-library-item-icon-color: color(dark);
$video-library-item-background--medium-down: color(global-background);
$video-library-item-border-radius--medium-down: 0;
$video-library-item-description-font-weight--large: normal;
$video-library-item-margin--large: rem-calc(0 0 20 0);
$video-library-item-description-max-lines--large: 2;
$video-library-item-description-line-height--large: 1.5;
$video-library-item-description-font-size--large: rem-calc(14);
$video-library-item-description-height--large: $video-library-item-description-line-height--large*$video-library-item-description-font-size--large*$video-library-item-description-max-lines--large;
$video-library-item-description-color--large: color(secondary);
$video-library-item-name-color: color(text);

$video-library-playlist-title-font: #{rem-calc(18)} / #{rem-calc(20)} $font-secondary;
$video-library-playlist-title-text-transform: none;
$video-library-playlist-margin: rem-calc(45 0 0);
$video-library-playlist-padding: rem-calc(45 0 0);
$video-library-playlist-title-margin: rem-calc(0 0 22);
$video-library-list-height--large: rem-calc(532);
$video-library-playlist-max-height--large: rem-calc(533);

@import "@lora/05-components/video-library";